import PropTypes from 'prop-types';
import React, { useState } from "react";

import { Typography, Box, IconButton, Popover } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';

import IconCebab from "../IconCebab";

CatalogSectionMenu.propTypes = {
  data: PropTypes.array,
};

CatalogSectionMenu.defaultProps = {
  data: []
}

export default function CatalogSectionMenu({ data }) {
  const style = {
    menuList: {
      background: '#ffffff',
      borderRadius: '12px',
      overflow: 'hidden',
      border: '1px solid #E7E8EA',
      marginBottom: '20px !important',
      display: {
        xs: 'none',
        sm: 'none',
        lg: 'block',
      },

      '& > div:nth-child(1)': {
        borderTop: 'none',
      }
    },
    subMenuList: {
      background: '#ffffff',
      borderRadius: '16px',

      '& .two-lvl': {
        paddingLeft: '40px',
      },
      '& .three-lvl': {
        paddingLeft: '60px',
      },
    },
    menuItem: {
      borderTop: '1px solid #E7E8EA',
      flexWrap: 'wrap',
      width: '100%',

      '&.none': {
        display: 'none',
      }
    },
    listItemText: {
      fontSize: '14px',
      color: '#0D1D32',
      padding: '9.5px 19px',
      cursor: 'pointer',
      fontWeight: '500',
      position: 'relative',

      '&:hover': {
        background: '#FFF8F3',
      },
      '& > svg': {
        position: 'absolute',
        right: '17px',
      }
    },
    listItemCount: {
      color: '#9EA5AD',
      marginLeft: '6px',
      fontSize: '14px',
    },
    burgerMenu: {
      display: {
        xs: 'flex',
        sm: 'flex',
        lg: 'none',
      },
      border: '1px solid #ffffff',
      background: '#ffffff',
      boxShadow: '0px 6px 16px rgba(13, 29, 50, 0.1)',
      borderRadius: '8px',
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: '16px',
      padding: '8px 18px',
    },
    floatMenu: {
      width: '100%',
      height: '100%',
      left: 0,

      '& > div:nth-child(3)': {
        left: '0 !important',
        top: '0 !important',
        maxWidth: '100% !important',
        maxHeight: '100% !important',
        width: '100%',
        height: '100%',
      },
      '& .mobilMenu': {
        padding: '14px 19px',
      }
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = () => {
    setAnchorEl(true);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showSubMenu, setShowSubMenu] = useState(false)

  const showMenu = () => {
    setShowSubMenu(true)
  }

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        sx={style.floatMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          className={'wrapCustomMenu'}
          sx={{
            color: '#0D1D32',
            background: '#ffffff',
            padding: '19px',
            width: '100%',
            height: '100%',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '25px 0px', }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400, }}>
              Категории
            </Typography>

            <CloseIcon onClick={handleClose} />
          </Box>

          <Box>
            {data.map(elem => {
              return (
                <Box className={elem.productsCount === 0 ? 'none' : null} key={elem.id} sx={style.menuItem}>
                  <Box className={'one-lvl mobilMenu'} onClick={elem.subCategories && elem.subCategories.length > 0 ? showMenu : null} sx={style.listItemText}>
                    <a href={`/category/${elem.id}`}>
                      {elem.title}

                      <Typography component={'span'} sx={style.listItemCount}>
                        {elem.productsCount}
                      </Typography>
                    </a>
                  </Box>

                  {elem.subCategories && elem.subCategories.length > 0 && showSubMenu > 0 && (
                    <Box sx={style.subMenuList}>
                      {elem.subCategories.map(sub_elem => {
                        return (
                          <Box className={sub_elem.productsCount === 0 ? 'none' : null} key={sub_elem.id} sx={style.menuItem}>
                            <Box className={'two-lvl mobilMenu'} sx={style.listItemText}>
                              <a href={`/category/${sub_elem.id}`}>
                                {sub_elem.title}

                                <Typography component={'span'} sx={style.listItemCount}>
                                  {sub_elem.productsCount}
                                </Typography>
                              </a>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              )
            })}
          </Box>
        </Box>
      </Popover>


      <Box sx={style.menuList}>
        <Box sx={{ background: '#F3F4F5', padding: '16px 19px', }}>
          <Typography component={"span"} sx={{ fontWeight: 500, fontSize: '18px', }}>Категории</Typography>
        </Box>
        {data.map(elem => {
          return (
            <Box className={elem.productsCount === 0 ? 'none' : null} key={elem.id} sx={style.menuItem}>
              <Box className={'one-lvl'} onClick={elem.subCategories && elem.subCategories.length > 0 ? showMenu : null} sx={style.listItemText}>
                <a href={`/category/${elem.id}`}>
                  {elem.title}

                  <Typography component={'span'} sx={style.listItemCount}>
                    {elem.productsCount}
                  </Typography>
                </a>
              </Box>

              {elem.subCategories && elem.subCategories.length > 0 && showSubMenu > 0 && (
                <Box sx={style.subMenuList}>
                  {elem.subCategories.map(sub_elem => {
                    return (
                      <Box className={sub_elem.productsCount === 0 ? 'none' : null} key={sub_elem.id} sx={style.menuItem}>
                        <Box className={'two-lvl'} sx={style.listItemText}>
                          <a href={`/category/${sub_elem.id}`}>
                            {sub_elem.title}

                            <Typography component={'span'} sx={style.listItemCount}>
                              {sub_elem.productsCount}
                            </Typography>
                          </a>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          )
        })}
      </Box>

      <IconButton edge="end" onClick={handleClick} sx={style.burgerMenu}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconCebab />
          <Typography sx={{ color: '#FF780F', fontSize: '16px', marginLeft: '10px' }}>
            Категории
          </Typography>
        </Box>

        <ArrowForwardIosIcon sx={{ color: '#CFD2D6', fontSize: '12px' }} />
      </IconButton>
    </>
  );
}